var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Wga,Xga,Yga,Zga,$ga,aha,bha,RF,cha,dha,eha,fha,gha,hha,iha,jha,TF,kha;$CLJS.NF=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Wga=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.OF=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Xga=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Yga=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.PF=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Zga=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.QF=new $CLJS.M(null,"display-info","display-info",-816930907);$ga=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);aha=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
bha=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);RF=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);cha=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);dha=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);eha=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);fha=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
gha=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);hha=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);iha=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.SF=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);jha=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);TF=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.UF=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);kha=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.BE($CLJS.Rw,$CLJS.H([$CLJS.$r,$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)],null)],null)],null)]));$CLJS.BE($CLJS.nF,$CLJS.H([$CLJS.$r,$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)],null)],null)],null)]));
$CLJS.zE($CLJS.gF,$CLJS.H([$CLJS.$r,$CLJS.KC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kE],null)],null)]));$CLJS.zE($CLJS.RE,$CLJS.H([$CLJS.$r,$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)],null)]));$CLJS.zE($CLJS.vF,$CLJS.H([$CLJS.$r,$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZD],null)],null)]));
$CLJS.zE($CLJS.xj,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null)],null)]));$CLJS.sE($CLJS.xj,$CLJS.eE);$CLJS.zE($CLJS.zF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kE],null)],null)]));$CLJS.sE($CLJS.zF,$CLJS.eE);$CLJS.zE($CLJS.qi,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null)],null)]));$CLJS.sE($CLJS.qi,$CLJS.eE);
$CLJS.Y(RF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.mr,new $CLJS.h(null,1,[$CLJS.us,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.us,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.zE($CLJS.YE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,RF],null)]));$CLJS.sE($CLJS.YE,$CLJS.eE);
$CLJS.zE($CLJS.tF,$CLJS.H([$CLJS.$r,$CLJS.KC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZD],null)],null)]));$CLJS.zE($CLJS.BF,$CLJS.H([$CLJS.$r,$CLJS.KC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kE],null)],null)]));$CLJS.zE($CLJS.yF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kE],null)],null)]));
$CLJS.zE($CLJS.IF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kE],null)],null)]));$CLJS.sE($CLJS.yF,$CLJS.eE);$CLJS.sE($CLJS.IF,$CLJS.eE);$CLJS.zE($CLJS.GF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZD],null)],null)]));$CLJS.sE($CLJS.GF,$CLJS.eE);
$CLJS.zE($CLJS.SE,$CLJS.H([$CLJS.$r,$CLJS.KC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kE],null)],null)]));$CLJS.Y(TF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.fr,jha,hha,aha,Xga,Wga,$ga,bha,cha,dha,gha,eha,kha,iha,Yga,fha,$CLJS.dl],null));$CLJS.Y($CLJS.OF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.qi,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TF],null)],null));
$CLJS.VF=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.fF,$CLJS.Rw,$CLJS.PF,!1,$CLJS.SF,$CLJS.GE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Count of rows"),$CLJS.XE,$CLJS.LD("Count"),$CLJS.Is,$CLJS.LD("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.fF,$CLJS.yF,$CLJS.NF,$CLJS.uj,$CLJS.PF,!0,$CLJS.SF,$CLJS.GE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Sum of ..."),$CLJS.XE,$CLJS.LD("Sum"),$CLJS.Is,$CLJS.LD("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.fF,$CLJS.gF,$CLJS.NF,$CLJS.uj,$CLJS.PF,!0,$CLJS.SF,$CLJS.GE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Average of ..."),$CLJS.XE,$CLJS.LD("Average"),$CLJS.Is,$CLJS.LD("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.fF,$CLJS.zF,$CLJS.NF,$CLJS.uj,$CLJS.PF,!0,$CLJS.SF,$CLJS.iF,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Median of ..."),$CLJS.XE,$CLJS.LD("Median"),$CLJS.Is,$CLJS.LD("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.fF,$CLJS.RE,$CLJS.NF,$CLJS.Zh,$CLJS.PF,!0,$CLJS.SF,$CLJS.GE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Number of distinct values of ..."),$CLJS.XE,$CLJS.LD("Distinct values"),$CLJS.Is,$CLJS.LD("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.fF,$CLJS.IF,$CLJS.NF,$CLJS.uj,$CLJS.PF,!0,$CLJS.SF,$CLJS.GE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Cumulative sum of ..."),
$CLJS.XE,$CLJS.LD("Sum"),$CLJS.Is,$CLJS.LD("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.fF,$CLJS.nF,$CLJS.PF,!1,$CLJS.SF,$CLJS.GE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Cumulative count of rows"),$CLJS.XE,$CLJS.LD("Count"),$CLJS.Is,$CLJS.LD("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.fF,$CLJS.BF,$CLJS.NF,$CLJS.uj,$CLJS.PF,
!0,$CLJS.SF,$CLJS.wF,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Standard deviation of ..."),$CLJS.XE,$CLJS.LD("SD"),$CLJS.Is,$CLJS.LD("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.fF,$CLJS.qi,$CLJS.NF,$CLJS.Ei,$CLJS.PF,!0,$CLJS.SF,$CLJS.GE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Minimum of ..."),$CLJS.XE,$CLJS.LD("Min"),$CLJS.Is,$CLJS.LD("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.fF,$CLJS.xj,$CLJS.NF,$CLJS.Ei,$CLJS.PF,!0,$CLJS.SF,$CLJS.GE,$CLJS.QF,function(){return new $CLJS.h(null,3,[$CLJS.nD,$CLJS.LD("Maximum of ..."),$CLJS.XE,$CLJS.LD("Max"),$CLJS.Is,$CLJS.LD("Maximum value of a column")],null)}],null)],null);
$CLJS.Y(Zga,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.UF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fF,$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.zj],null),$CLJS.cf.h($CLJS.fF),$CLJS.VF)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Di],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PF,$CLJS.vr],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.SF,$CLJS.Di],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,$CLJS.jd],null)],null));