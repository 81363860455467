var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var nE,wE,tE,Vga;nE=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=nE[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=nE._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("Cached.-cache",a);}return a};
$CLJS.oE=function(a,b,c){if(null!=a&&$CLJS.yc===a.Wa){var d=nE(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.zh.v(d,$CLJS.R,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.qE=function(a,b,c){a=$CLJS.pE.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.rE=function(a,b){return $CLJS.qE(a,b,null)};$CLJS.sE=function(a,b){$CLJS.zh.v($CLJS.fB,$CLJS.gA,a,b)};$CLJS.uE=function(a,b){a=tE(a,b);return $CLJS.n(a)?a:$CLJS.$i};
wE=function(){return $CLJS.Wf.j(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,2,[$CLJS.vi,$CLJS.z,$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.K.g(a,$CLJS.bj);return $CLJS.vd(a)?["Invalid ",$CLJS.xh.l($CLJS.H([$CLJS.z(a)]))," clause: ",$CLJS.xh.l($CLJS.H([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zn,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.us,"not a known MBQL clause"],null),$CLJS.Pe(!1)],null)],null)],null),$CLJS.cf.h(function(a){return new $CLJS.P(null,
2,5,$CLJS.Q,[a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.mh.g("mbql.clause",$CLJS.Xg(a))],null)],null)}),$CLJS.q(vE))};$CLJS.zE=function(a,b){$CLJS.Ne.v($CLJS.xE,$CLJS.yE,a,b)};$CLJS.BE=function(a,b){$CLJS.Ne.v($CLJS.xE,AE,a,b)};$CLJS.uo.prototype.ia=$CLJS.ua(26,function(){return this.cache});$CLJS.wo.prototype.ia=$CLJS.ua(25,function(){return this.cache});$CLJS.yo.prototype.ia=$CLJS.ua(24,function(){return this.cache});$CLJS.Ao.prototype.ia=$CLJS.ua(23,function(){return this.cache});
$CLJS.Co.prototype.ia=$CLJS.ua(22,function(){return this.cache});$CLJS.Eo.prototype.ia=$CLJS.ua(21,function(){return this.cache});$CLJS.Ho.prototype.ia=$CLJS.ua(20,function(){return this.cache});$CLJS.Jo.prototype.ia=$CLJS.ua(19,function(){return this.cache});$CLJS.Lo.prototype.ia=$CLJS.ua(18,function(){return this.cache});$CLJS.No.prototype.ia=$CLJS.ua(17,function(){return this.cache});$CLJS.Po.prototype.ia=$CLJS.ua(16,function(){return this.cache});$CLJS.Ro.prototype.ia=$CLJS.ua(15,function(){return this.cache});
$CLJS.To.prototype.ia=$CLJS.ua(14,function(){return this.cache});$CLJS.Vo.prototype.ia=$CLJS.ua(13,function(){return this.cache});$CLJS.Xo.prototype.ia=$CLJS.ua(12,function(){return this.cache});$CLJS.Zo.prototype.ia=$CLJS.ua(11,function(){return this.cache});$CLJS.bp.prototype.ia=$CLJS.ua(10,function(){return this.cache});$CLJS.gp.prototype.ia=$CLJS.ua(9,function(){return this.cache});$CLJS.ip.prototype.ia=$CLJS.ua(8,function(){return this.cache});$CLJS.lp.prototype.ia=$CLJS.ua(7,function(){return this.cache});
$CLJS.vp.prototype.ia=$CLJS.ua(6,function(){return this.cache});$CLJS.UC.prototype.ia=$CLJS.ua(5,function(){return this.cache});$CLJS.pE=function pE(a){switch(arguments.length){case 1:return pE.h(arguments[0]);case 2:return pE.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.pE.h=function(a){return $CLJS.pE.g(a,null)};$CLJS.pE.g=function(a,b){return $CLJS.oE($CLJS.En.g(a,b),$CLJS.Kj,$CLJS.Jm)};$CLJS.pE.A=2;
$CLJS.CE=function CE(a,b){return $CLJS.Hh($CLJS.NC,a,b)||$CLJS.Ad($CLJS.Me(function(d){return CE.g?CE.g(a,d):CE.call(null,a,d)},$CLJS.xk.h(b)))||$CLJS.Ad($CLJS.Me(function(d){return CE.g?CE.g(d,b):CE.call(null,d,b)},$CLJS.Jh(a)))};
tE=function tE(a,b){return $CLJS.E.g(a,$CLJS.$i)?null:$CLJS.E.g(b,$CLJS.$i)?null:$CLJS.CE(a,b)?b:$CLJS.CE(b,a)?a:$CLJS.Me(function(d){return $CLJS.Me(function(e){return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[d,e],null),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))?null:tE.g?tE.g(d,e):tE.call(null,d,e)},$CLJS.ae(b,$CLJS.Jh(b)))},$CLJS.ae(a,$CLJS.Jh(a)))};$CLJS.DE=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.EE=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.FE=new $CLJS.M(null,"dimension","dimension",543254198);$CLJS.GE=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.HE=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.IE=new $CLJS.M(null,"query","query",-1288509510);$CLJS.JE=new $CLJS.M(null,"power","power",-937852079);$CLJS.KE=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.LE=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.ME=new $CLJS.M(null,"abs","abs",-246026477);
$CLJS.NE=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.OE=new $CLJS.M(null,"between","between",1131099276);$CLJS.PE=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.QE=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.RE=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.SE=new $CLJS.M(null,"var","var",-769682797);$CLJS.TE=new $CLJS.M(null,"next","next",-117701485);$CLJS.UE=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.VE=new $CLJS.M(null,"case","case",1143702196);
$CLJS.WE=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.XE=new $CLJS.M(null,"column-name","column-name",551523580);$CLJS.YE=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.ZE=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.$E=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.aF=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.bF=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.cF=new $CLJS.M(null,"desc","desc",2093485764);
$CLJS.dF=new $CLJS.M(null,"starts-with","starts-with",366503009);$CLJS.eF=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.fF=new $CLJS.M(null,"short","short",1928760516);$CLJS.gF=new $CLJS.M(null,"avg","avg",197406200);$CLJS.hF=new $CLJS.M(null,"metric","metric",408798077);$CLJS.iF=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.jF=new $CLJS.M(null,"asc","asc",356854569);$CLJS.kF=new $CLJS.M(null,"floor","floor",1882041021);
$CLJS.lF=new $CLJS.M(null,"contains","contains",676899812);$CLJS.mF=new $CLJS.M(null,"field","field",-1302436500);$CLJS.nF=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.oF=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.pF=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.qF=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.rF=new $CLJS.M(null,"round","round",2009433328);
$CLJS.sF=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);$CLJS.tF=new $CLJS.M(null,"share","share",-589433933);$CLJS.uF=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.vF=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.wF=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.xF=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.yF=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.zF=new $CLJS.M(null,"median","median",569566131);$CLJS.AF=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);Vga=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.BF=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.CF=new $CLJS.M(null,"trim","trim",774319767);$CLJS.DF=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.EF=new $CLJS.M(null,"ltrim","ltrim",1654269283);
$CLJS.FF=new $CLJS.M(null,"category","category",-593092832);$CLJS.GF=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.HF=new $CLJS.M(null,"/","/",1282502798);$CLJS.IF=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.JF=new $CLJS.M(null,"log","log",-1595516004);$CLJS.KF=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.LF=new $CLJS.M(null,"rtrim","rtrim",-661336449);var vE=$CLJS.Se($CLJS.Sg),AE;$CLJS.Y($CLJS.PD,wE());$CLJS.gB(vE,Vga,function(){return $CLJS.Y($CLJS.PD,wE())});
$CLJS.MF=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.ND.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.mh.g("mbql.clause",$CLJS.Xg(d));$CLJS.Y(f,e);$CLJS.Dd($CLJS.q(vE),d)||$CLJS.zh.j(vE,$CLJS.Yd,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
AE=function AE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return AE.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
AE.l=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.Wf.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Yq,new $CLJS.h(null,1,[$CLJS.us,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,a],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.xD],null)],null)],null)],null),b)],null)};AE.A=1;
AE.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};$CLJS.yE=function yE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return yE.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.yE.l=function(a,b){return $CLJS.Wf.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.h(null,1,[$CLJS.us,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.xD],null)],null),b)};$CLJS.yE.A=1;$CLJS.yE.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.xE=function xE(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xE.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.xE.l=function(a,b,c){c=$CLJS.E.g($CLJS.z(c),$CLJS.$r)?$CLJS.ae($CLJS.dd(c),$CLJS.Xe(2,c)):$CLJS.ae(null,c);var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);a=$CLJS.Ne.j(a,b,d);return $CLJS.n(c)?$CLJS.MF.v(b,$CLJS.$r,c,a):$CLJS.MF.g(b,a)};$CLJS.xE.A=2;
$CLJS.xE.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return this.l(b,a,c)};