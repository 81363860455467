var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var fU,gU,hU,jU,kU,lU,mU,nU,oU,pU;fU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);gU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);hU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.iU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);jU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);kU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);lU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
mU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);nU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);oU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);pU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(fU,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.us,"Valid template tag :widget-type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.aU)));$CLJS.Y(jU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zj,$CLJS.JL,$CLJS.yM,$CLJS.FE,$CLJS.Qs,$CLJS.Ej,$CLJS.EO],null));
$CLJS.Y(mU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.sD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nD,$CLJS.sD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.sD,$CLJS.ur],null)],null)],null));
$CLJS.Y(nU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.dl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$M,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.vr],null)],null)],null));
$CLJS.Y(kU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.FE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Li,new $CLJS.h(null,1,[$CLJS.zr,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.wj],null)],null)],null)],null));
$CLJS.Y(lU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.JL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vL,$CLJS.sD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WO,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.hK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XO,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.WJ],null)],null)],null));
$CLJS.Y(pU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,$CLJS.yM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eQ,$CLJS.VJ],null)],null)],null));$CLJS.Y(oU,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.zj],null),$CLJS.PT));
$CLJS.Y(gU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oU],null)],null)],null)],null));
$CLJS.Y(hU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lr,new $CLJS.h(null,1,[$CLJS.vi,$CLJS.Pi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zn,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gU],null)],null)],null)],null));
$CLJS.Y($CLJS.iU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.sD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.us,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));